import useSWR from 'swr';
import { useTranslation } from '@dssf/component-library';
import { useAuth } from '../context/auth';
import { getIndexed } from '../services/getIndexed';
import { useSpaceConfig, useSpaceId } from '../context/spaceConfig';
import { LLMOption, SpaceConfigInterface, SpaceProvider } from '../types/spaceConfig';
import { Indexed } from '../types/indexed';
import { AppFlavourService } from '../services/appFlavourService';
import { SpaceAdministrationService } from '../services/spaceAdministrationService';
import { useMemo } from 'react';

export const fallbackLLMId = 'GPT3';
export const fallbackSpaceId = 'PUBLIC';
export const fallbackSpaceProvider = 'PUBLIC';
export const defaultFavouriteSpaces: String[] = [];
export const PUBLIC_SPACE = 'public';

export interface UseSpacesReturn {
  spaces: SpaceConfigInterface[];
  indexedSpaces: Indexed<SpaceConfigInterface>;
  currentSpace: SpaceConfigInterface | null;
  indexedLLMs: Indexed<Indexed<LLMOption>>;
  currentSpaceIndexedLLMs: Indexed<LLMOption> | null;
  reloadSpaces: (provider?: SpaceProvider) => Promise<SpaceConfigInterface[] | undefined>;
  isLoading: boolean;
}

export interface UseSpaceLLMsReturn {
  currentLLM: LLMOption | null;
}

export function useSpaces(): UseSpacesReturn {
  const { lang } = useTranslation();
  const { token } = useAuth();
  const { currentSpaceId, handleSpaceUpdate } = useSpaceId();

  const getSpaces = async (provider?: SpaceProvider) => {
    const result = await SpaceAdministrationService.getSpaces(lang, token, provider);
    handleSpaceUpdate(result);
    return result;
  };

  // due to using hooks doing this inside a loop is not really possible, but number of providers is limited
  const {
    data: publicData,
    isLoading: publicLoading,
    mutate: publicMutate,
  } = useSWR(`PUBLIC-${lang}`, () => getSpaces('PUBLIC'), { revalidateIfStale: false });
  const {
    data: brainData,
    isLoading: brainLoading,
    mutate: brainMutate,
  } = useSWR(AppFlavourService.isBeta && `BRAIN-${lang}`, () => getSpaces('BRAIN'), {
    revalidateIfStale: false,
  });
  const {
    data: ragData,
    isLoading: ragLoading,
    mutate: ragMutate,
  } = useSWR(AppFlavourService.isBeta && `RAG-${lang}`, () => getSpaces('RAG'), {
    revalidateIfStale: false,
  });
  const {
    data: skynetData,
    isLoading: skynetLoading,
    mutate: skynetMutate,
  } = useSWR(AppFlavourService.isBeta && `SKYNET-${lang}`, () => getSpaces('SKYNET'), {
    revalidateIfStale: false,
  });

  const spaces = useMemo(
    () => [...(publicData ?? []), ...(brainData ?? []), ...(ragData ?? []), ...(skynetData ?? [])],
    [publicData, brainData, ragData, skynetData],
  );
  const indexedSpaces = useMemo(() => getIndexed(spaces), [spaces]);
  const indexedLLMs =
    (indexedSpaces &&
      Object.fromEntries(
        Object.entries(indexedSpaces).map(([id, space]) => [id, getIndexed(space.config?.llms ?? [])]),
      )) ??
    null;

  const reloadSpaces = async (provider?: SpaceProvider): Promise<SpaceConfigInterface[] | undefined> => {
    switch (provider) {
      case 'PUBLIC':
        return publicMutate();
      case 'BRAIN':
        return brainMutate();
      case 'RAG':
        return ragMutate();
      case 'SKYNET':
        return skynetMutate();
      default:
        return (await Promise.all([publicMutate(), brainMutate(), ragMutate(), skynetMutate()]))
          .flat()
          .filter(e => e) as SpaceConfigInterface[];
    }
  };

  return {
    spaces,
    indexedSpaces,
    currentSpace: (currentSpaceId && indexedSpaces[currentSpaceId]) || null,
    indexedLLMs,
    currentSpaceIndexedLLMs: (currentSpaceId && indexedLLMs[currentSpaceId]) || null,
    isLoading: publicLoading || brainLoading || ragLoading || skynetLoading,
    reloadSpaces,
  };
}

export function useSpaceLLMs(): UseSpaceLLMsReturn {
  const { currentSpaceId, currentLLMId } = useSpaceConfig();
  const { indexedLLMs } = useSpaces();

  return {
    currentLLM: (currentLLMId && indexedLLMs?.[currentSpaceId ?? '']?.[currentLLMId]) || null,
  };
}
